/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

//APT theme files
@import "npm-font-open-sans/open-sans.css";
@import "@archinsurance-da/arch-portal-tools/theme/arch-portal-tools.theme.scss";
//@import "@progress/kendo-theme-default/dist/all.scss";

body {
  font-family: $FontFamily;
  margin: 0;
  padding: 0;
  color: $text-color;
}
